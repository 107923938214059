<template>
  <basic-container>
    <avue-skeleton :loading="waiting"
                   avatar
                   :rows="8">
      <avue-affix id="avue-view"
                  :offset-top="114">
        <div class="header">
          <avue-title :value="process.processDefinitionName"></avue-title>
<!--          <div v-if="process.status != 'todo'">-->
<!--            主题：<avue-select v-model="theme"-->
<!--                         size="mini"-->
<!--                         :clearable="false"-->
<!--                         :dic="themeList"></avue-select>-->
<!--          </div>-->
        </div>
      </avue-affix>
      <el-tabs v-model="activeName" style="background-color: #FFFFFF;padding: 12px" type="card">
        <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadFirst`)"
                     name="first">
          <el-card shadow="never">
            <div id="printBody"
                 :class="process.status != 'todo' ? `wf-theme-${theme}`: ''">
              <avue-form v-if="option && ((option.column && option.column.length > 0) || (option.group && option.group.length > 0))"
                         v-model="form"
                         ref="form"
                         :defaults.sync="defaults"
                         :option="option"
                         :upload-preview="handleUploadPreview">
              </avue-form>
            </div>
          </el-card>
          <el-card shadow="never"
                   style="margin-top: 20px"
                   v-if="process.status == 'todo'">
            <wf-examine-form ref="examineForm"
                             :comment.sync="comment"
                             :process="process"
                             @user-select="handleUserSelect"></wf-examine-form>
          </el-card>
        </el-tab-pane>
        <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadSecond`)"
                     name="second">
          <el-card shadow="never"
                   >
            <wf-flow :flow="flow"></wf-flow>
          </el-card>
        </el-tab-pane>
        <el-tab-pane :label="$t(`cip.desk.workflow.title.indexHeadThird`)"
                     name="third">
          <template v-if="activeName == 'third'">
            <el-card shadow="never"
                     >
              <wf-design ref="bpmn"
                         style="height: 500px;"
                         :options="bpmnOption"></wf-design>
            </el-card>
          </template>
        </el-tab-pane>
      </el-tabs>
    </avue-skeleton>

    <!-- 底部按钮 -->
    <wf-button :loading="submitLoading"
               :button-list="buttonList"
               :process="process"
               :comment="comment"
               @examine="handleExamine"
               @user-select="handleUserSelect"
               @print="handlePrint"
               @rollback="handleRollbackTask"
               @terminate="handleTerminateProcess"
               @withdraw="handleWithdrawTask"></wf-button>
    <!-- 人员选择弹窗 -->
    <user-select ref="user-select"
                 :check-type="checkType"
                 :default-checked="defaultChecked"
                 @onConfirm="handleUserSelectConfirm"></user-select>
  </basic-container>
</template>

<script>
import WfExamineForm from '@/views/plugin/workflow/process/components/examForm.vue'
import WfButton from '@/views/plugin/workflow/process/components/button.vue'
import WfFlow from '@/views/plugin/workflow/process/components/flow.vue'
import userSelect from '@/views/plugin/workflow/process/components/user-select'

import exForm from '@/views/plugin/workflow/mixins/ex-form'
import theme from '@/views/plugin/workflow/mixins/theme'

export default {
  mixins: [exForm, theme],
  components: { userSelect, WfExamineForm, WfButton, WfFlow },
  watch: {
    '$route.query.p': {
      handler(val) {
        if (val) {
          const param = JSON.parse(Buffer.from(val, 'base64').toString())
          const { taskId, processInsId } = param
          if (taskId && processInsId) this.getDetail(taskId, processInsId)
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      activeName: 'first',
      defaults: {},
      form: {},
      option: {
        column: [
          {
            type: 'input',
            label: this.$t('cip.desk.workflow.field.creator'),
            span: 12,
            display: true,
            prop: 'creator',
            value: '${this.$store.getters.userInfo.nick_name}',
            readonly: true
          },
          {
            type: 'input',
            label: this.$t('cip.desk.workflow.field.createDept'),
            span: 12,
            display: true,
            prop: 'createDept',
            value: '${this.$store.getters.userInfo.dept_name}',
            readonly: true
          },
          {
            type: 'datetimerange',
            label: this.$t('cip.desk.workflow.field.datetime'),
            span: 12,
            display: true,
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            prop: 'datetime',
            required: true,
            rules: [
              {
                required: true,
                message: this.$t(`cip.desk.workflow.msg.beginTime`),
              }
            ],
            change: ({ value }) => {
              if (!value || value.length == 0) {
                this.$set(this.form, 'days', undefined)
              } else {
                const d1 = Date.parse(value[0])
                const d2 = Date.parse(value[1])
                const day = (d2 - d1) / (1 * 24 * 60 * 60 * 1000)
                this.$set(this.form, 'days', Number(day.toFixed(2)))
              }
            }
          },
          {
            type: 'number',
            label: this.$t('cip.desk.workflow.field.days'),
            span: 12,
            display: true,
            prop: 'days',
            required: true,
            rules: [
              {
                required: true,
                message: this.$t('cip.desk.workflow.msg.askLeaveTime'),
              }
            ],
            controls: true,
            controlsPosition: 'right',
            change: ({ value }) => {
              this.$set(this.form, 'reason', '请假' + value + '天')
            }
          },
          {
            type: 'textarea',
            label:  this.$t('cip.desk.workflow.field.reason'),
            span: 24,
            display: true,
            prop: 'reason',
            required: true,
            rules: [
              {
                required: true,
                message: this.$t('cip.desk.workflow.msg.askReasonTime'),
              }
            ]
          },
          {
            label:  this.$t('cip.desk.workflow.field.originalName'),
            type: 'upload',
            propsHttp: {
              res: 'data',
              url: 'link',
              name: 'originalName'
            },
            action: '/api/sinoma-resource/oss/endpoint/put-file',
            display: true,
            span: 24,
            showFileList: true,
            multiple: true,
            limit: 10,
            prop: 'attachment'
          },
        ]
      },
      vars: [], // 需要提交的字段
      submitLoading: false, // 提交时按钮loading
    }
  },
  methods: {
    // 获取任务详情
    getDetail(taskId, processInsId) {
      this.getTaskDetail(taskId, processInsId).then(res => {
        const { process, form } = res
        const { variables, status } = process

        let { taskForm } = form
        console.log("res",res)
        const option = this.option
        option.menuBtn = false
        const { column, group } = option
        if (status != 'todo') { // 已办，删除字段默认值
          option.detail = true
          if (column && column.length > 0) { // 处理column
            column.forEach(col => {
              if (col.type == 'dynamic') col.children.column.forEach(cc => {
                delete cc.value
              })
              delete col.value
            })
          }

          if (group && group.length > 0) { // 处理group
            group.forEach(gro => {
              if (gro.column && gro.column.length > 0) {
                gro.column.forEach(col => {
                  if (col.type == 'dynamic') col.children.column.forEach(cc => {
                    delete cc.value
                  })
                  delete col.value
                })
              }
            })
          }
        } else {
          const columnFilter = this.filterAvueColumn(column, taskForm, true)
          const columnArr = columnFilter.column
          let vars = columnFilter.vars || []

          const groupArr = []
          if (group && group.length > 0) { // 处理group
            group.forEach(gro => {
              const groupFilter = this.filterAvueColumn(gro.column, taskForm, true)
              gro.column = groupFilter.column
              vars = vars.concat(groupFilter.vars)
              if (gro.column.length > 0) groupArr.push(gro)
            })
          }
          option.column = columnArr
          option.group = groupArr
          this.vars = vars
        }
        for (let key in variables) {
          if (!variables[key]) delete variables[key]
        }

        if (option.column && process.variables && process.variables.serialNumber) {
          option.column.unshift({
            label:  this.$t('cip.desk.workflow.field.SerialNumber'),
            prop: 'serialNumber',
            span: 24,
            detail: true,
          })
        }

        this.option = option
        this.form = variables
        this.waiting = false
      })
    },
    // 审核
    handleExamine(pass) {
      this.submitLoading = true
      this.$refs.form.validate((valid, done) => {
        if (valid) {
          const variables = {}
          this.vars.forEach(v => {
            if (v != 'comment' && this.form[v]) variables[v] = this.form[v]
          })

          this.handleCompleteTask(pass, variables).then(() => {
            this.$message.success("处理成功")
            this.handleCloseTag('/plugin/workflow/process/todo')
          }).catch(() => {
            done()
            this.submitLoading = false
          })
        } else {
          done()
          this.submitLoading = false
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .bjs-powered-by {
  display: none;
}
.header {
  //width: 100%;
  height: 50px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px 0;
}
</style>
